import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfigB2C";
import { useDispatch } from "react-redux";
import axios from "../../utils/axios";
import jwt_decode from "jwt-decode";
import encryptData from "../../utils/encryption";
import {
  setAccessToken,
  setUserDetails,
  setUserName,
} from "../../redux/redux/global/action";

function B2CAuth(props) {  
  // debugger;
  const history = useHistory();
  const [idToken, setIdToken] = useState(null);
  const [validDealer, setValidDealer] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const isAuthenticated=useIsAuthenticated();
 
  let B2CAccount = accounts.filter(accounts => accounts.homeAccountId.includes(process.env.REACT_APP_B2C_TENANTID));
  console.log("B2CAccount", B2CAccount);
 
 
  useEffect(() => {
    if (accounts.length == 0 || B2CAccount.length == 0) {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    }

  }, []);

  useEffect(() => {
    // if ( accounts.length > 0) 
    if(isAuthenticated)
    {
      requestB2CToken();
      getDealerData();
    }
    
  }, [isAuthenticated]);


  useEffect(() => {
    if (isAuthenticated && validDealer) {
      props.setIsAuthenticated(true);
      history.push({
        pathname: "/dashboard",
      });
    }
  }, [validDealer]);

  useEffect(() => {
    if(errorMessage!=""){
          history.push({
            pathname: `/`,
            state:{errorMessage:errorMessage}
            });
      }
   }, [errorMessage]);


 

  const requestB2CToken = async () => {
    const request = {
      ...loginRequest,
      account: B2CAccount[0],
    };
    console.log("requestB2CToken", request);

    try {
      const response = await instance.acquireTokenSilent(request);
      setIdToken(response.idToken);
    } catch (error) {
      await instance.acquireTokenPopup(request);
    }
  };

  const getDealerData=async() =>{
    // debugger;
    let email = B2CAccount[0].username;
    let value = {
    User: email,
    Password: "qwertyuioigfdsasdfghjkkmnbvcvesdxcvghuytrfdgtfghyg",
    };
    value = encryptData(JSON.stringify(value), "enc");
    console.log("delaerData Value",value);
  
    axios
    .post("/authentication/login/", { body: value }, {
    headers: {},
    })
    
    .then((response) => {
      console.log("Response", response)
    if (response.status === 200) {
        localStorage.setItem('Type', "Non Employee");
        
        let token = response?.data?.data;
        let accessData = response?.data?.accessibleMenu; 
        console.log("if accessdata ", accessData);
        let JWT = jwt_decode(token);
        console.log('jwt',JWT);
        if(accessData){
          console.log('in if accessData', accessData);
        JWT = {
            ...JWT,
            accessData: [...accessData.split(',')]
        }
        }
        dispatch(setUserDetails(JWT));
       
        dispatch(setAccessToken(token));
        dispatch(setUserName(JWT.um_name));
        setValidDealer(true);
        // props.setIsAuthenticated(true);
        
    }
    else{
        
        setErrorMessage("Invalid user!");
        setValidDealer(false);
    }
    })
    .catch((e) => {
    
    if (e?.response?.data?.message) {
        setErrorMessage(e?.response?.data?.message);
        setValidDealer(false);
        
    }
    })
    .finally(() => {
    });
  }


return (
    <>
  </>
    );

}
export default B2CAuth;